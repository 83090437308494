<template>
  <div id="app" :class="hasHeader?'header':'noheader'">
    <headers v-if='hasHeader'></headers>
    <router-view/>
    <tabbar v-if="hasTab"></tabbar>
  </div>
</template>
<script>
import headers from '@/components/header.vue'
import tabbar from '@/components/tabbar.vue'
export default {
  components: {
    headers,
    tabbar
  },
	data() {
		return{
      hasHeader: true,
      hasTab: false
    }
  },
  watch: {
    $route(to,from) {
      console.log(to)
      if (to.name == 'home'||to.name=='web3') {
        this.hasHeader = true;
      } else {
        this.hasHeader = false;
      }
      if (to.name == 'index'||to.name == 'exchange') {
        this.hasTab = false;
      } else {
        this.hasTab = true;
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="less">
html,body{
  height: 100%;
}
#app{
  min-height: 100vh;
  background: #151515;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 0;
  color: #fff;
  font-size: 16px;
  padding-bottom: 50px;
  // overflow-y: auto;
}
.flex{
  display: flex;
}
.align-items-center{
  align-items: center;
}
.justify-content-between{
  justify-content: space-between;
}
.justify-content-center{
  justify-content: center;
}
.flex-direction{
  flex-direction: column;
}
.container{
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
*{
  box-sizing: border-box;
}
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}

/* ios input样式清除 */
input,
textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 28px;
  outline: none;
  border: 0;
  -webkit-appearance: none;
  background-color: transparent;
}

input[disabled],
input:disabled,
input.disabled {
  color: #282828;
  -webkit-text-fill-color: #3e3e3e;
  -webkit-opacity: 1;
  opacity: 1;
}

/* number样式清除 */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.van-popup {
  background: #000000 !important;
  border-radius: 30px 30px 0 0;
}
.van-action-sheet {
  color: #F9E37B !important;
}
.van-cell {
  background: transparent !important;
  color: #ffffff !important;
}
.van-collapse-item__content {
  background: transparent !important;
  color: #c4c6cb !important;
}
.van-radio__label{
  color: #F9E37B !important;
  font-size: 12px;
}
.van-radio__label .van-radio__icon--checked .van-icon{
  color: #F9E37B !important;
}
:root {
  --van-collapse-item-content-text-color: '#9ea1ad';
  --van-collapse-item-content-background-color: '#18191d';
  --van-collapse-item-title-disabled-color: '#18191d';
}
</style>
