const chinese = {
  errmsg: '出现问题啦～',
  up: '上级地址：',
  bindup: '绑定上级',
  confirm: '确认',
  toast10: '已发起，等待区块确认',
  toast13: '获取地址失败',
  total: '总认购',
  total1: 'EGA余额',
  total2: '动态奖励',
  toast4: '余额不足',
  withraw: '提取',
  team: '团队业绩',
  team1: '团队',
  team2: '人',
  team3: '直推',
  one: '仅限购一次',
  onetime: '周期',
  oneprice: '限额',
  day: '天',
  buy: '认购',
  sheng: '剩余',
  income: '预计收益',
  title: '托管 ',
  time: '期限',
  close: '提前关闭',
  timedown: '挖矿倒计时：',
  daoqi: '到期',
  buyed: '已认购',
  incomed: '已收益',
  nodata: '暂时还没有托管的POOL',
  tiyan: '体验 ',
  oneNum: '限额认购',
  allIncome: '总收益',
  balance: '可用资产',
  submit: '确认认购',
  poolAll: 'POOL总量',
  shengyu: '剩余可购',
  buynum: '认购金额',
  input: '输入金额',
  max: '最大',
  title1: '天POOL-提取收益',
  can: '可提取',
  sure: '确认提取',
  title2: '体验POOL-提取收益',
  tab1: '托管中',
  tab2: '已结束',
  tian: '天',
  shi: '时',
  fen: '分',
  miao: '秒',
  alert1: '关闭',
  alert2: '确认提前关闭吗',
  alert3: '请输入地址',
  alert4: '请输入数量',
  alert5: '余额不足',
  alert6: '最低投资金额',
  end: '结束',
  tabbar1: '资产包理财',
  tabbar2: '生态',
  tabbar3: '智投',
  noopen: '暂未开放 敬请期待',
  ketiqu: '可提取收益',
  jingtai: '静态收益',
  jiedian: '节点收益',
  futou: '确认复投',
  shifang: '已释放',
  confirm1: '开启',
  confirm2: '确认重新开启吗',
  cancel: '取消',
  copyLink: '复制邀请链接',
  copySuccess: '复制成功',
  reOpen: '重新开启',
  everyDay: '每日提取额度',
  todayNum: '今日剩余额度',
  minnum: '最低提取',
  exchange1: '兑 换',
  exchange2: '发送 (余额:',
  exchange3: '接收 (预估)',
  add: '本金',
  add1: '收益复投',
  add2: '本金复投',
  add3: '每日收益率:',
  add4: '未提取本金:',
  add5: '最小0.1BNB且为0.1的倍数',
  add6: '总销毁',
  add7: '总产出收益',
  add8: '我的认购',
  add9: '直推有效',
  add10: '本金释放中',
  add11: '本金已释放：',
  add12: '可提取本金：',
  add13: '当前收益率',
  add14: '认购单价',
  add15: '认购价格',
  add16: '待挖总量',
  add17: '已提取',
  add18: '每天释放时间',
  add19: '我的矿池',
  add20: '认购记录',
  nft1: '商店',
  nft2: '背包',
  nft3: '立即购买',
  nft4: '转出',
  nft5: '转出地址',
  nft6: '转出数量',
  newBuy1: '暴富仓',
  newBuy2: '当前金额：',
  newBuy3: '下次释放时间：',
  newBuy4: '下次释放数量：',
  newBuy5: '加仓',
  newBuy6: '加仓数量',
  // pool page
  poolPageIncome1: '礦池收益',
  poolPageIncome2: '團隊收益',
  poolPageBuy1: '購買礦池',
  poolPageBuy2: '最小購買',
  poolPageBuy3: '最大購買',
  poolPagePool1: '認購：',
  poolPagePool2: '已發放',
  poolPagePool3: '已產出收益',
  poolPagePool4: '認購時間',
  
}
export default chinese;
