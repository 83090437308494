<template>
  <div id="tabbar">
    <van-tabbar active-color="#ccec44" inactive-color="#aeb8c6" v-model="active" @change="switchTab" z-index="1000">
      <van-tabbar-item>
        <span>{{$t('tabbar1')}}</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>NFT</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>EGA {{$t('tabbar2')}}</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>SWAP</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active : icon3.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant';
export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return{
      active: 0,
      icon: {
        inactive: require('@/image/tab1.png'),
        active: require('@/image/tab2.png'),
      },
      icon1: {
        inactive: require('@/image/tab5.png'),
        active: require('@/image/tab6.png'),
      },
      icon2: {
        inactive: require('@/image/tab3.png'),
        active: require('@/image/tab4.png'),
      },
      icon3: {
        inactive: require('@/image/tab7.png'),
        active: require('@/image/tab8.png'),
      },
      routes: [
        '/home',
        '/nft',
        '/ecology',
        '/swap'
      ]
    }
  },
  watch: {
    $route(to,from) {
      for (let i = 0; i < this.routes.length; i++) {
        if (this.routes[i] == to.path) {
          this.active = i;
        }
      }
    }
  },
  mounted() {
    for (let i = 0; i < this.routes.length; i++) {
      if (this.routes[i] == this.$route.fullPath) {
        this.active = i;
      }
    }
  },
  methods: {
    switchTab(index) {
      this.$router.replace(this.routes[index])
    }
  }

}
</script>

<style lang="less">
.van-tabbar,.van-tabbar-item--active{
  background: #000;
}
.van-tabbar::after{
  border-top:1px solid #ccec44
}
</style>